@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.caterer {
  &__main {
    .lazy-load-image-loaded {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  &__featured-image {
    margin-bottom: 1rem;
  }

  &__sidebar {
    &-button.button{
      margin-top: 0px;
    }

    &-label {
      text-transform: uppercase;
      color: $dark-gray;
      font-size: .875rem;
    }

    &-inner {
      line-height: 1.5;

      p {
        line-height: 1.5;
      }
    }

    .lazy-load-image-loaded {
      margin-top: 1rem;
    }
  }
}